@import "@/src/styles/common.scss";
.lazy-load-container {
    .home-need-help {
        background: #fff;
        padding: 0.64rem 0.2rem 0.4rem;
        text-align: center;
        h1 {
            font-size: 0.4rem;
            font-family: var(--font-montserrat-bold);

            color: rgba(29, 32, 35, 1);
            line-height: 0.38rem;
        }
        .h5-classify {
            &.find-myfit {
                width: 6.78rem;
                height: 0.8rem;
                margin: 0.64rem auto 0.57rem;
            }
            &.myfit-info {
                padding: 0.18rem;
                margin: 0.36rem 0.15rem 0.23rem;
                & > *:first-child {
                    margin-bottom: 0.33rem;
                }
            }
        }
        ul.connect_to_help_ul {
            margin: 0.58rem 0 0;
            list-style: none;
            padding: 0;
            li {
                display: flex;
                align-items: center;
                font-size: 0.28rem;
                font-family: var(--font-montserrat);
                color: $text_2;
                margin-top: 0.2rem;
                &.show_hide {
                    display: none;
                }
                .fm_button {
                    display: flex;
                    justify-content: flex-start;
                    height: fit-content;
                    height: 0.52rem;
                    font-family: var(--font-montserrat);
                    align-items: center;
                    color: inherit;
                    background-color: transparent;
                    border: none;
                    border-radius: 0;
                    box-shadow: none;
                    padding: 0;
                    &:hover {
                        background-color: unset;
                        border: none;
                        p {
                            color: $text_3;
                        }
                    }
                    &.flex_container {
                        display: flex;
                        position: relative;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        .flex_content {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // background-color: rebeccapurple;
                        }
                    }
                    .track_search {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }
                    .icon-left {
                        line-height: 1;
                        font-size: 0.4rem;
                    }
                    .list-icon-font {
                        @include use-icon-normal(null, $text_2);
                    }
                }
                p {
                    margin-left: 0.2rem;
                    margin-bottom: 0;
                }
            }
        }
    }
}
