@import "@/src/styles/common.scss";
.share-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0.4rem 0.2rem;
    .h5-share-btn {
        border-radius: 50%;
        width: 0.6rem;
        height: 0.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &.ins-2x-image {
            height: 0.6rem !important;
            width: auto;
        }
    }
    .fb-btn {
        background: #537bbc;
    }
    .twitter-btn {
        background: $main;
    }
    .youtube-btn {
        background: #cb2027;
    }
    .pinterest-btn {
        background: #cb2027;
    }
    .share-btn-container:not(:first-child) {
        margin-left: 0.2rem;
    }
}
